<template>
  <svg viewBox="0 0 31.322 34.294">
    <g id="np_magic_2704149_000000" transform="translate(-15.086 -11.773)">
      <path id="Pfad_7" data-name="Pfad 7" d="M61.2,35.74,63.041,33.9l1.842,1.842-1.842,1.842Z" transform="translate(-25.429 -12.201)" />
      <path id="Pfad_8" data-name="Pfad 8" d="M15.086,59.606,35.028,39.664l1.842,1.842L16.928,61.447Z" transform="translate(0 -15.38)" />
      <path id="Pfad_9" data-name="Pfad 9" d="M73.16,52.553a3.591,3.591,0,0,1-2.691-3.624.526.526,0,0,0-1.034-.154,3.6,3.6,0,0,1-3.625,2.691.526.526,0,0,0-.152,1.034,3.591,3.591,0,0,1,2.69,3.625.526.526,0,0,0,1.034.152,3.6,3.6,0,0,1,3.625-2.691.526.526,0,0,0,.154-1.034Z" transform="translate(-27.671 -20.188)" />
      <path id="Pfad_10" data-name="Pfad 10" d="M77.7,14.732a2.564,2.564,0,0,1-3.212-.231.526.526,0,0,0-.79.685A2.562,2.562,0,0,1,73.47,18.4a.525.525,0,0,0,.685.788,2.564,2.564,0,0,1,3.212.231.526.526,0,0,0,.79-.683,2.561,2.561,0,0,1,.23-3.212.526.526,0,0,0-.683-.79Z" transform="translate(-32.119 -1.428)" />
      <path id="Pfad_11" data-name="Pfad 11" d="M35.388,15.3a4.919,4.919,0,0,1,2.3,5.742.526.526,0,0,0,.96.412,4.922,4.922,0,0,1,5.742-2.3.526.526,0,0,0,.412-.962,4.921,4.921,0,0,1-2.3-5.742.526.526,0,0,0-.96-.412,4.927,4.927,0,0,1-5.744,2.3.526.526,0,0,0-.412.96Z" transform="translate(-11.049)" />
    </g>
  </svg>
</template>

<script lang="ts">

import {Vue} from "vue-class-component";

export default class EdisWandIcon extends Vue {

}

</script>

import {NavigationGuardNext} from "vue-router";
import store from '@/store/index'

export default function checkAuth(next: NavigationGuardNext): void {
    const isAuthenticated = store.state.LoginStore.token
    if (!isAuthenticated) {
        next()
    } else {
        next('/');
    }
}

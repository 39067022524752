
import {Options, Vue} from "vue-class-component";
import {InboxInIcon, PencilIcon, PlusSmIcon, TrashIcon} from "heroicons-vue3/outline";
import EmailRecordsResponse, {DomainRecord, EmailAddress} from "@/services/api/models/EmailRecordsResponse";
import {namespace} from "vuex-class";
import Alert from "@/common/components/Alert.vue";
import EmailStore from "@/modules/email/store/email.store";
import {EmailType} from "@/modules/email/models/email-type";

const emailStore = namespace("EmailStore")
const loginStore = namespace("LoginStore")

@Options({
  components: {
    PlusSmIcon,
    TrashIcon,
    PencilIcon,
    Alert,
    InboxInIcon
  },
})
export default class EmailList extends Vue {
  @emailStore.Getter('records') records?: EmailRecordsResponse
  @emailStore.Getter('domains') domains?: string[] = undefined
  @emailStore.Getter('isLoading') loading!: boolean
  @emailStore.Action deleteMail!: (address: string) => void
  @loginStore.Action logout!: () => void

  filterType?: number = 0
  query?: string = ''

  onTypeChange(event: Event) {
    console.log('onTypeChange', this.filterType)
  }

  onQueryChange(event: Event) {
    console.log('onQueryChange', this.query)
  }

  deleteMailFromStore(address: string) {
    if (confirm("Wollen Sie die E-Mail Adresse wirklich löschen?")) {
      this.deleteMail(address)
    }
  }

  public isCatchAll(email: string): boolean {
    return email.startsWith('@')
  }

  public recipient(address: EmailAddress): string {
    return address.Recipients ? address.Recipients.join(", ") : '-'
  }

  public type(record: DomainRecord, email: string): EmailType {
    if (this.isCatchAll(email)) {
      return EmailType.CATCHALL
    }

    if (record.addresses) {
      const address = record.addresses[email]
      if (address.Recipients && address.Recipients.length) {
        return EmailType.FORWARD
      } else {
        return EmailType.EMAIL
      }
    }

    return EmailType.UNKNOWN
  }
}

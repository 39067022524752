
import { Options, Vue } from "vue-class-component";
import { DownloadIcon } from "heroicons-vue3/outline";

@Options({
  components: {
    DownloadIcon
  }
})
export default class UserBilling extends Vue {
  people = [
    { 
      title: 'Rechnung - Hostingpaket XL.pdf', 
      range: '14.04.2021 - 14.05.2021', 
      createdOn: '17.05.2021',
      paid: true
    },
    { 
      title: 'Rechnung - Hostingpaket XL.pdf', 
      range: '14.04.2021 - 14.05.2021', 
      createdOn: '17.05.2021',
      paid: true
    },
    { 
      title: 'Rechnung - Hostingpaket XL.pdf', 
      range: '14.04.2021 - 14.05.2021', 
      createdOn: '17.05.2021',
      paid: true
    },
    { 
      title: 'Rechnung - Hostingpaket XL.pdf', 
      range: '14.04.2021 - 14.05.2021', 
      createdOn: '17.05.2021',
      paid: true
    }
  ]
}
    

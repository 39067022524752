<template>
    <h1 class="text-2xl font-semibold text-gray-900 text-left">
        Stammdaten
    </h1>

    <div class="mt-12">
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Titel
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Zeitraum
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Ausstellungsdatum
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Rechnung beglichen
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Download</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(person, personIdx) in people" :key="person.email" :class="personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {{ person.title }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{ person.range }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{ person.createdOn }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{ person.paid ? 'Bezahlt' : 'Noch offen' }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <a href="#" class="text-system-blue hover:text-system-dark-blue">
                        <DownloadIcon class="h-4 w-4"></DownloadIcon>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { DownloadIcon } from "heroicons-vue3/outline";

@Options({
  components: {
    DownloadIcon
  }
})
export default class UserBilling extends Vue {
  people = [
    { 
      title: 'Rechnung - Hostingpaket XL.pdf', 
      range: '14.04.2021 - 14.05.2021', 
      createdOn: '17.05.2021',
      paid: true
    },
    { 
      title: 'Rechnung - Hostingpaket XL.pdf', 
      range: '14.04.2021 - 14.05.2021', 
      createdOn: '17.05.2021',
      paid: true
    },
    { 
      title: 'Rechnung - Hostingpaket XL.pdf', 
      range: '14.04.2021 - 14.05.2021', 
      createdOn: '17.05.2021',
      paid: true
    },
    { 
      title: 'Rechnung - Hostingpaket XL.pdf', 
      range: '14.04.2021 - 14.05.2021', 
      createdOn: '17.05.2021',
      paid: true
    }
  ]
}
    
</script>
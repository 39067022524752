import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import Webservice from './services/api/webservice'
import store from './store'
import Toast from "vue-toastification";

import './styles/app.css'
import "vue-toastification/dist/index.css";

// create app
const application = createApp(App)

// boot up
application
    .use(store)
    .use(router)
    .use(Toast, {})
    .mount('#app')

// init global props
application.config.globalProperties.$api = new Webservice();

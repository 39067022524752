<template>
  <div class="flex items-center">
    <h1 class="text-2xl font-semibold text-gray-900 text-left flex-1">
      Übersicht E-Mail Adressen
    </h1>
    <router-link to="/email/create" class="bg-system-blue hover:bg-system-dark-blue text-white font-bold py-2 px-4 rounded-full inline-flex items-center">
      <PlusSmIcon class="h-4 w-4 mr-1"></PlusSmIcon>
      <span>Neue E-Mail anlegen</span>
    </router-link>
  </div>

  <div class="mt-12">
    <div class="flex flex-col">
      <div class="filter mb-4 flex">
        <div class="mr-2">
          <label for="location" class="sr-only">Typ</label>
          <select @change="onTypeChange($event)" v-model="filterType" id="location" name="location" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
            <option value="0">Alle Typen anzeigen</option>
            <option value="1">Postfach</option>
            <option value="2">Catch-All</option>
            <option value="3">Weiterleitung</option>
          </select>
        </div>

        <div class="mr-2">
          <label for="domain" class="sr-only">Domain</label>
          <div>
            <select id="domain" name="domain" class="shadow-sm focus:ring-system-blue focus:border-system-blue block w-full sm:text-sm border-gray-300 rounded-md">
              <option value="">Domain wählen...</option>
              <option v-for="domain in domains" :key="domain">{{ domain }}</option>
            </select>
          </div>
        </div>

        <div class="flex-1">
          <label for="email" class="sr-only">Email</label>
          <input v-model="query" @change="onQueryChange($event)" type="text" name="email" id="email" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Suchbegriff eingeben, um in E-Mail Adressen zu suchen">
        </div>
      </div>
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Typ
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Autoresponder
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ziel
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Aktionen
                </th>
              </tr>
              </thead>

              <template v-if="loading">
                <tbody>
                  <tr class="animate-pulse" v-for="index in 10" :key="index">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <div class="h-4 bg-system-gray-3 rounded"></div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <div class="h-4 bg-system-gray-3 rounded"></div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <div class="h-4 bg-system-gray-3 rounded"></div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <div class="h-4 bg-system-gray-3 rounded"></div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <div class="h-4 bg-system-gray-3 rounded"></div>
                    </td>
                  </tr>
                </tbody>
              </template>

              <template v-else>
                <tbody v-for="(record, domain) in records" :key="domain">
                <tr><td class="bg-system-gray-3 px-6 py-1 text-sm font-medium text-white" colspan="5">{{ domain }}</td></tr>

                <tr v-if="Object.keys(record.addresses || {}).length === 0" class="bg-white">
                  <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900" colspan="5">Keine E-Mail Adresse angelegt</td>
                </tr>

                <tr v-else v-for="(addressDetails, address, idx) in record.addresses" :key="address" :class="idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                  <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {{ address }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ type(record, address) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    todo
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ recipient(addressDetails) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <a v-if="!isCatchAll(address) && type(record, address) !== 'forward'" href="https://afterlogic.edis.at/" target="_blank" class="mr-3 h-5 w-5 text-system-blue " type="button"><InboxInIcon></InboxInIcon></a>
                    <router-link :to="{ name: 'email-edit', params: { address }}" class="mr-3 h-5 w-5 text-system-blue " type="button"><PencilIcon></PencilIcon></router-link>
                    <button class="mr-3 h-5 w-5 text-system-red " type="button" @click="deleteMailFromStore(address)"><TrashIcon></TrashIcon></button>
                  </td>
                </tr>
                </tbody>
              </template>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import {InboxInIcon, PencilIcon, PlusSmIcon, TrashIcon} from "heroicons-vue3/outline";
import EmailRecordsResponse, {DomainRecord, EmailAddress} from "@/services/api/models/EmailRecordsResponse";
import {namespace} from "vuex-class";
import Alert from "@/common/components/Alert.vue";
import EmailStore from "@/modules/email/store/email.store";
import {EmailType} from "@/modules/email/models/email-type";

const emailStore = namespace("EmailStore")
const loginStore = namespace("LoginStore")

@Options({
  components: {
    PlusSmIcon,
    TrashIcon,
    PencilIcon,
    Alert,
    InboxInIcon
  },
})
export default class EmailList extends Vue {
  @emailStore.Getter('records') records?: EmailRecordsResponse
  @emailStore.Getter('domains') domains?: string[] = undefined
  @emailStore.Getter('isLoading') loading!: boolean
  @emailStore.Action deleteMail!: (address: string) => void
  @loginStore.Action logout!: () => void

  filterType?: number = 0
  query?: string = ''

  onTypeChange(event: Event) {
    console.log('onTypeChange', this.filterType)
  }

  onQueryChange(event: Event) {
    console.log('onQueryChange', this.query)
  }

  deleteMailFromStore(address: string) {
    if (confirm("Wollen Sie die E-Mail Adresse wirklich löschen?")) {
      this.deleteMail(address)
    }
  }

  public isCatchAll(email: string): boolean {
    return email.startsWith('@')
  }

  public recipient(address: EmailAddress): string {
    return address.Recipients ? address.Recipients.join(", ") : '-'
  }

  public type(record: DomainRecord, email: string): EmailType {
    if (this.isCatchAll(email)) {
      return EmailType.CATCHALL
    }

    if (record.addresses) {
      const address = record.addresses[email]
      if (address.Recipients && address.Recipients.length) {
        return EmailType.FORWARD
      } else {
        return EmailType.EMAIL
      }
    }

    return EmailType.UNKNOWN
  }
}
</script>

import {Options, prop, Vue} from "vue-class-component";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationIcon } from "heroicons-vue3/outline";
import {AvailableSpaceProps} from "@/common/components/AvailableSpace.vue";

export class AlertProps {
  test = prop<boolean>({ default: false })
}

@Options({
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExclamationIcon,
  }
})
export default class Alert extends Vue.with(AlertProps) {
  open = this.test

  get isOpen() {
    return this.open
  }

  set isOpen(value: boolean) {
    this.open = value
    this.$emit(value ? 'onClose' : 'onOpen', value)
  }

  onPositive() {
    this.isOpen = false
    this.$emit('onPositive')
  }
  onCancel() {
    this.isOpen = false
    this.$emit('onCancel')
  }
}

import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import EmailRecordsResponse, {
  EmailAddress,
} from "@/services/api/models/EmailRecordsResponse";
import store from "@/store";
import Webservice from "@/services/api/webservice";
import { catchError, finalize, map } from "rxjs/operators";

export type EmailLoadingFunction = () => void;

@Module({
  namespaced: true,
})
export default class EmailStore extends VuexModule {
  data: EmailRecordsResponse = {};
  email?: EmailAddress = undefined;
  loading = false;

  @Mutation
  setRecords(data: EmailRecordsResponse): void {
    this.data = data;
  }

  @Mutation
  setEmail(email: EmailAddress): void {
    this.email = email;
  }

  @Mutation
  setLoading(loading: boolean): void {
    this.loading = loading;
  }

  @Action
  refreshMailRecords() {
    this.context.commit("setLoading", true);
    const userData = store.state.UserStore.userData;
    if (userData) {
      new Webservice().email.getMails(userData.CustNo!).subscribe(
        (value: EmailRecordsResponse) => {
          this.context.commit("setRecords", value);
        },
        (error) => {
          console.log("error:", error);
        },
        () => {
          this.context.commit("setLoading", false);
        }
      );
    }
  }

  @Action
  deleteMail(address: string) {
    this.context.commit("setLoading", true);
    new Webservice().email
      .deleteMail(address)
      .subscribe((value: EmailRecordsResponse) => {
        this.context.dispatch("refreshMailRecords");
      });
  }

  @Action
  loadEmail(address: string) {
    this.context.commit("setLoading", true);
    return new Webservice().email
      .getMail(address)
      .pipe(
        map((value) => {
          this.context.commit("setEmail", value);
          return value;
        }),
        finalize(() => {
          this.context.commit("setLoading", false);
        })
      )
      .toPromise();
  }

  get records() {
    const keys = Object.keys(this.data);
    const data: EmailRecordsResponse = {};

    keys.forEach((key) => {
      if (
        this.data[key] &&
        this.data[key].addresses &&
        Object.keys(this.data[key]).length > 0
      ) {
        data[key] = this.data[key];
      }
    });

    return data;
  }

  get domains(): string[] {
    return Object.keys(this.data || {});
  }

  get isLoading() {
    return this.loading;
  }

  get loadedEmail() {
    return this.email;
  }
}

<template>
  <h1 class="text-2xl font-semibold text-gray-900">
    Domains
  </h1>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
})
export default class Domains extends Vue {}
</script>

import {DomainRecord} from "@/services/api/models/EmailRecordsResponse";

export default class Utils {
    public static formatBytes(bytes: number, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = Math.max(0, decimals);
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        const result = bytes / Math.pow(k, i)

        return result.toFixed(2) + ' ' + sizes[i];
    }
}
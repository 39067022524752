<template>
  <svg viewBox="0 0 25.803 22">
    <path id="np_profile_1888903_000000" d="M39.2,31.693a12.9,12.9,0,1,0-22.049,9.1,4.817,4.817,0,0,1,3.338-3.836l3.836-1.285a.319.319,0,0,1,.22.007,4.578,4.578,0,0,0,3.5,0,.312.312,0,0,1,.22-.007L32.1,36.957a4.817,4.817,0,0,1,3.338,3.836,12.916,12.916,0,0,0,3.759-9.1ZM28.328,34.319a4.452,4.452,0,0,1-4.056,0,6.617,6.617,0,0,1-3.372-5.8,5.661,5.661,0,0,1,5.4-5.877,5.66,5.66,0,0,1,5.4,5.877,6.619,6.619,0,0,1-3.375,5.8Z" transform="translate(-13.398 -18.793)"/>
  </svg>
</template>

<script lang="ts">

import {Vue} from "vue-class-component";

export default class EdisProfileIcon extends Vue {

}

</script>

<template>
    <div class="bg-white overflow-hidden shadow rounded-lg flex flex-col">
        <div class="p-5 flex-1">
            <div class="flex items-start">
                <div class="flex-shrink-0">
                    <div class="bg-system-blue rounded-md">
                        <slot name="icon"></slot>
                    </div>
                </div>
                <div class="ml-5 w-0 flex-1">
                    <dl>
                        <dt class="text-sm font-medium text-gray-500 truncate text-left">
                            <slot name="title"></slot>
                        </dt>
                        <dd>
                        <div class="text-lg font-medium text-gray-900 text-left">
                            <slot></slot>
                        </div>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        <div class="bg-gray-50 px-5 py-3">
            <div class="text-sm">
                <router-link :to="link" class="font-medium text-system-blue hover:text-system-dark-blue">Alle anzeigen</router-link>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component } from '@vue/runtime-core';
import { Options, Vue } from 'vue-class-component';


class Props {
  link!: string
}

export default class FooterCard extends Vue.with(Props) {

}
</script>
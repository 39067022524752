import { NavigationGuardNext } from "vue-router";
import store from "@/store/index";
import Webservice from "@/services/api/webservice";
import { UserResponse } from "@/services/api/models/UserResponse";
import { ApiResponse } from "@/services/api/models/ApiResponse";
import { namespace } from "vuex-class";

export default function checkAuth(next: NavigationGuardNext): void {
  const isAuthenticated = store.state.LoginStore.token;

  //TODO user holen, 403 non auth? dann login
  //Nur token persistent, rest dann über webservice einfach holen mittels token
  //von common zu stores (userstore)
  if (isAuthenticated) {
    new Webservice().user.userData().subscribe(
      (value: ApiResponse<UserResponse>) => {
        store.commit("UserStore/setUserData", value.data);
        next();
      },
      (error) => {
        console.log("error:", error);
        store.commit("LoginStore/logout");
        next("/login");
      }
    );
  } else {
    console.log("moving to login");
    next("/login");
  }
}


import {Options, Vue} from 'vue-class-component';
import store from "@/store";
import EmailRecordsResponse from "@/services/api/models/EmailRecordsResponse";
import {namespace} from "vuex-class";

const emailStore = namespace("EmailStore")

@Options({ })
export default class Email extends Vue {
  @emailStore.Action refreshMailRecords!: () => void

  created() {
    this.refreshMailRecords()
  }
}

<template>
  <svg viewBox="0 0 33.607 30.554">
    <path id="np_server_2657122_000000" d="M35.484,19.026H6.459a2.291,2.291,0,0,0-2.291,2.291V25.9a2.291,2.291,0,0,0,2.291,2.291H35.484A2.291,2.291,0,0,0,37.775,25.9V21.317A2.291,2.291,0,0,0,35.484,19.026ZM15.625,23.609H7.986V22.081h7.639Zm3.82,0H17.152V22.081h2.291Zm16.04,6.111H6.459a2.291,2.291,0,0,0-2.291,2.291v4.583a2.292,2.292,0,0,0,2.291,2.291H35.484a2.292,2.292,0,0,0,2.291-2.291V32.011a2.291,2.291,0,0,0-2.291-2.291ZM9.514,35.066a.764.764,0,0,1-1.528,0V33.538a.764.764,0,0,1,1.528,0Zm3.055,0a.763.763,0,1,1-1.527,0V33.538a.763.763,0,1,1,1.527,0Zm3.055,0h0a.764.764,0,0,1-1.528,0V33.538a.764.764,0,0,1,1.528,0Zm11.457.763h0a1.526,1.526,0,1,1,1.08-.447,1.528,1.528,0,0,1-1.08.447Zm5.346,0h0a1.528,1.528,0,1,1,1.08-.447,1.528,1.528,0,0,1-1.08.447Zm3.056-27.5H6.459a2.292,2.292,0,0,0-2.291,2.291v4.583A2.291,2.291,0,0,0,6.459,17.5H35.483a2.291,2.291,0,0,0,2.291-2.291V10.623A2.292,2.292,0,0,0,35.483,8.332ZM9.514,13.68a.764.764,0,0,1-1.528,0V12.152a.764.764,0,0,1,1.528,0Zm3.055,0a.763.763,0,1,1-1.527,0V12.152a.763.763,0,1,1,1.527,0Zm3.055,0h0a.764.764,0,0,1-1.528,0V12.152a.764.764,0,0,1,1.528,0Zm11.457.763h0A1.526,1.526,0,1,1,28.162,14a1.528,1.528,0,0,1-1.08.447Zm5.346,0h0A1.528,1.528,0,1,1,33.508,14a1.528,1.528,0,0,1-1.08.447Z" transform="translate(-4.168 -8.332)"/>
  </svg>
</template>

<script lang="ts">

import {Vue} from "vue-class-component";

export default class EdisRackIcon extends Vue {

}

</script>


import { Options, Vue } from 'vue-class-component';
import {namespace} from "vuex-class";
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { LogoutIcon, ChevronDownIcon } from "heroicons-vue3/outline";
import { EdisCloudIcon, EdisEarthIcon, EdisProfileIcon, EdisRackIcon, EdisSheetsIcon } from '../icons';

const loginStore = namespace('LoginStore')

@Options({
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    ChevronDownIcon,
    LogoutIcon,
    EdisProfileIcon,
    EdisSheetsIcon,
    EdisEarthIcon,
    EdisRackIcon,
    EdisCloudIcon
  }
})
export default class Header extends Vue {
  @loginStore.Mutation
  public logout!: () => void

  @loginStore.State
  private token?: string

  solutions = [
    {
      name: 'Stammdaten',
      href: '/user',
      icon: EdisProfileIcon
    },
    {
      name: 'Rechnungen',
      href: '/billing',
      icon: EdisSheetsIcon
    },
    {
      name: 'Domains',
      href: '#',
      icon: EdisEarthIcon
    },
    {
      name: 'Hostings',
      href: '#',
      icon: EdisRackIcon
    }
  ]
}

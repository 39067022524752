<template>
  <svg viewBox="0 0 33.607 33.595">
    <g transform="translate(-5 -5.02)">
      <path d="M8.361,5.019h8.3A3.61,3.61,0,0,1,20.022,8.38v4.9a3.362,3.362,0,0,1-3.361,3.361h-8.3A3.61,3.61,0,0,1,5,13.283V8.38A3.362,3.362,0,0,1,8.361,5.019Z" />
      <path d="M58.131,5.019h8.3A3.61,3.61,0,0,1,69.792,8.38V19.9a3.362,3.362,0,0,1-3.361,3.361h-8.3A3.61,3.61,0,0,1,54.77,19.9V8.38A3.362,3.362,0,0,1,58.131,5.02Z" transform="translate(-31.185 0)" />
      <path d="M8.361,46.148h8.3a3.61,3.61,0,0,1,3.361,3.361V61.025a3.362,3.362,0,0,1-3.361,3.361h-8.3A3.61,3.61,0,0,1,5,61.025V49.509A3.362,3.362,0,0,1,8.361,46.148Z" transform="translate(0 -25.771)" />
      <path d="M58.131,63.852h8.3a3.61,3.61,0,0,1,3.361,3.361v4.9a3.362,3.362,0,0,1-3.361,3.361h-8.3a3.61,3.61,0,0,1-3.361-3.361v-4.9a3.362,3.362,0,0,1,3.361-3.361Z" transform="translate(-31.185 -36.864)" />
    </g>
  </svg>
</template>

<script lang="ts">

import {Vue} from "vue-class-component";

export default class EdisDashboardIcon extends Vue {

}

</script>

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import checkAuth from "@/middlewares/checkAuth";
import checkLogin from "@/middlewares/checkLogin";
import Email from "@/views/Email.vue";
import Installer from "@/views/Installer.vue";
import Webspace from "@/views/Webspace.vue";
import Domains from "@/views/Domains.vue";
import Amazon from "@/views/Amazon.vue";
import UserBaseData from "@/modules/user/components/UserBaseData.vue";
import UserBilling from "@/modules/user/components/UserBilling.vue";
import EmailList from "@/modules/email/components/EmailList.vue";
import EmailCreation from "@/modules/email/components/EmailCreation.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Admin.vue"),
    beforeEnter: (to, from, next) => checkAuth(next),
    children: [
      {
        path: "",
        redirect: "dashboard",
      },
      {
        path: "dashboard",
        component: Dashboard,
      },
      {
        path: "email",
        component: Email,
        children: [
          {
            name: "email-list",
            path: "",
            component: EmailList,
          },
          {
            name: "email-create",
            path: "create",
            component: EmailCreation,
          },
          {
            name: "email-edit",
            path: "edit/:address",
            component: EmailCreation,
          },
        ],
      },
      {
        path: "email-create",
        component: EmailCreation,
      },
      {
        path: "installer",
        component: Installer,
      },
      {
        path: "webspace",
        component: Webspace,
      },
      {
        path: "domains",
        component: Domains,
      },
      {
        path: "amazon",
        component: Amazon,
      },
      {
        path: "user",
        component: UserBaseData,
      },
      {
        path: "billing",
        component: UserBilling,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    beforeEnter: (to, from, next) => checkLogin(next),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

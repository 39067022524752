<template>
  <div class="flex items-center">
    <h1 class="text-2xl font-semibold text-gray-900 text-left flex-1">
      {{ isEditMode ? 'E-Mail Adresse bearbeiten' : 'Neue E-Mail Adresse anlegen' }}
    </h1>
  </div>

  <div class="mt-12">
    <form class="space-y-8" action="#" method="post">

      <fieldset class="flex" :disabled="isEditMode" :class="!isEditMode ? 'opacity-1' : 'opacity-50'" >
        <label class="text-sm font-medium text-gray-700 flex items-center mr-6">
          <input v-model="formData.type" name="type" value="email" type="radio" class="focus:ring-system-blue h-4 w-4 text-system-blue border-gray-300 mr-2" />
          E-Mail Postfach
        </label>
        <label class="text-sm font-medium text-gray-700 flex items-center mr-6">
          <input v-model="formData.type" name="type" value="forward" type="radio" class="focus:ring-system-blue h-4 w-4 text-system-blue border-gray-300 mr-2" />
          Weiterleitung
        </label>
        <label class="text-sm font-medium text-gray-700 flex items-center">
          <input v-model="formData.type" name="type" value="catchall" type="radio" class="focus:ring-system-blue h-4 w-4 text-system-blue border-gray-300 mr-2" />
          Catch-All
        </label>
      </fieldset>

      <fieldset>
        <div class="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-3">
            <label for="address" class="block text-sm font-medium text-gray-700">
              {{ isEditMode ? 'Adresse' : 'Neue Adresse' }}
            </label>
            <div class="mt-1 flex items-center">
              <input :disabled="isEditMode || isCatchAll" id="address" v-model="formData.address" type="text" name="address" autocomplete="address-title"  :class="isEditMode || isCatchAll ? 'opacity-50' : 'opacity-1'" class="shadow-sm focus:ring-system-blue focus:border-system-blue block w-full sm:text-sm border-gray-300 rounded-md" />
              <span class="ml-3">@</span>
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="domain" class="block text-sm font-medium text-gray-700">
              {{ isEditMode ? 'Domain' : 'Domain auswählen' }}
            </label>
            <div class="mt-1">
              <select :disabled="isEditMode" v-model="formData.domain" id="domain" name="domain" :class="!isEditMode ? 'opacity-1' : 'opacity-50'" class="shadow-sm focus:ring-system-blue focus:border-system-blue block w-full sm:text-sm border-gray-300 rounded-md">
                <option value="">Domain wählen...</option>
                <option v-for="domain in domains" :key="domain">{{ domain }}</option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-3" v-if="!isForward && !isCatchAll">
            <label for="password" class="block text-sm font-medium text-gray-700">
              Passwort
            </label>
            <div class="mt-1 flex items-center">
              <input id="password" v-model="formData.password" type="password" name="password" class="shadow-sm focus:ring-system-blue focus:border-system-blue block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:col-span-3" v-if="!isForward && !isCatchAll">
            <label for="password_confirm" class="block text-sm font-medium text-gray-700">
              Passwort
            </label>
            <div class="mt-1 flex items-center">
              <input id="password_confirm" v-model="formData.passwordConfirm" type="password" name="password" class="shadow-sm focus:ring-system-blue focus:border-system-blue block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:col-span-6 flex justify-end" v-if="isEditMode && !isForward && !isCatchAll">
            <button type="button" @click="changePassword" class="bg-system-blue hover:bg-system-dark-blue text-white font-bold py-2 px-4 rounded-full inline-flex items-center">
              <span>Passwort ändern</span>
            </button>
          </div>
        </div>
      </fieldset>

      <fieldset v-if="isForward || isCatchAll">
        <div class="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-6">
            <label for="recipientRow" class="block text-sm font-medium text-gray-700">
              Zieladresse hinzufügen
            </label>
            <div class="mt-1 flex items-center" :class="(isCatchAll && formData.recipients?.length) ? 'opacity-50' : 'opacity-1'" >
              <input id="recipientRow" :disabled="isCatchAll && formData.recipients?.length" v-model="recipientRow" type="text" name="address" autocomplete="address-title" class="shadow-sm focus:ring-system-blue focus:border-system-blue block w-full sm:text-sm border-gray-300 rounded-md" />
              <button type="button" :disabled="isCatchAll && formData.recipients?.length" @click="addParticipantRow"><PlusSmIcon class="ml-4 h-4 w-4 bg-system-blue text-white rounded-full inline-flex items-center"></PlusSmIcon></button>
            </div>
          </div>
          <div class="sm:col-span-6">
            <div class="flex items-center mb-1" v-for="(row, idx) in formData.recipients" :key="idx">
              <button class="mr-2 h-5 w-5 text-system-red " type="button" @click="deleteRecipient(idx)"><TrashIcon></TrashIcon></button>
              <div class="font-bold">{{ row }}</div>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset v-else>
        <div class="bg-system-gray py-8 px-4 border-2 border-system-gray-border sm:rounded-lg sm:px-10">
          <div class="grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
            <div class="sm:col-span-3">
              <span class="font-bold text-xl">Autoresponder</span>
            </div>
            <div class="sm:col-span-3 text-right">
              <Toggle @onToggle="onAutoresponderToggle">
                <span class="mr-2">Aktiv?</span>
              </Toggle>
            </div>
            <div class="sm:col-span-3">
              <label for="responder_from" class="block text-sm font-medium text-gray-700">
                Gültig vom (tt.mm.yyyy)
              </label>
              <div class="mt-1">
                <input id="responder_from" v-model="formData.responder.start" type="text" name="responder_from" autocomplete="responder-from" class="shadow-sm focus:ring-system-blue focus:border-system-blue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="ab sofort" />
              </div>
            </div>

            <div class="sm:col-span-3">
              <label for="responder_to" class="block text-sm font-medium text-gray-700">
                Gültig bis (tt.mm.yyyy)
              </label>
              <div class="mt-1">
                <input id="responder_to" v-model="formData.responder.end" type="text" name="responder_to" autocomplete="responder-to" class="shadow-sm focus:ring-system-blue focus:border-system-blue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="bis auf Widerruf"/>
              </div>
            </div>

            <div class="sm:col-span-3">
              <label for="subject" class="block text-sm font-medium text-gray-700">
                Betreff des Autoresponders
              </label>
              <div class="mt-1">
                <input id="subject" v-model="formData.responder.subject" type="text" name="responder_to" autocomplete="responder-to" class="shadow-sm focus:ring-system-blue focus:border-system-blue block w-full sm:text-sm border-gray-300 rounded-md" placeholder="bis auf Widerruf"/>
              </div>
            </div>

            <div class="sm:col-span-6">
              <label for="message" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Nachricht des Autoresponders
              </label>
              <div class="mt-1">
                <textarea id="message" v-model="formData.responder.message" name="message" rows="3" class="shadow-sm block w-full focus:ring-system-blue focus:border-system-blue sm:text-sm border border-gray-300 rounded-md" />
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div class="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-3">
            <router-link :to="{ name: 'email-list' }" type="button" class="mr-3 bg-transparent hover:bg-system-dark-blue text-system-blue font-bold py-2 px-4 hover:text-white inline-flex items-center border border-system-blue hover:border-transparent rounded-full">
              <span>Zurück</span>
            </router-link>
            <button v-if="isEditMode" type="button" class="bg-system-red hover:bg-system-dark-blue text-white font-bold py-2 px-4 rounded-full inline-flex items-center" @click="deleteMailFromStore">
              <span>Löschen</span>
            </button>
          </div>

          <div class="sm:col-span-3 text-right" v-if="!isEditMode || isForward">
            <button type="button" @click="createMail" class="bg-system-blue hover:bg-system-dark-blue text-white font-bold py-2 px-4 rounded-full inline-flex items-center">
              <span>{{ isEditMode ? 'Speichern' : 'Anlegen' }}</span>
            </button>
          </div>
        </div>
      </fieldset>

    </form>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import {PlusSmIcon, TrashIcon} from "heroicons-vue3/outline";
import {Toggle} from '@/common/components'
import {namespace} from "vuex-class";
import router from "@/router";
import {Observable} from "rxjs";
import {EmailAddress} from "@/services/api/models/EmailRecordsResponse";
import {EmailType} from "@/modules/email/models/email-type";

interface EmailFormData {
  type: EmailType,
  address: string
  domain: string
  recipients: string[],
  password: string,
  passwordConfirm: string,
  responder: {
    enabled: boolean,
    start?: string,
    end?: string,
    subject: string,
    message: string
  }
}

const emailStore = namespace("EmailStore")

@Options({
  components: {
    PlusSmIcon,
    TrashIcon,
    Toggle
  }
})
export default class EmailCreation extends Vue {

  @emailStore.Getter('domains') domains?: string[] = undefined
  @emailStore.Getter('loadedEmail') email!: EmailAddress

  @emailStore.Action refreshMailRecords!: () => void
  @emailStore.Action deleteMail!: (address: string) => Observable<any>
  @emailStore.Action loadEmail!: (address: string) => Promise<any>

  recipientRow = ''

  formData: EmailFormData = {
    type: EmailType.EMAIL,
    address: '',
    domain: '',
    recipients: [],
    password: '',
    passwordConfirm: '',
    responder: {
      enabled: false,
      start: '',
      end: '',
      subject: '',
      message: ''
    }
  }

  beforeMount() {
    if (this.isEditMode) {
      this.loadEmail(this.emailParam)
          .then(value => {
            this.prepareFormData()
          })
    }
  }

  get emailParam(): string {
    const address = this.$route.params.address
    return Array.isArray(address) ? address[0] : address
  }

  get isEditMode() {
    return this.emailParam || false
  }

  get isForward() {
    return this.formData.type === EmailType.FORWARD
  }

  get isCatchAll() {
    return this.formData.type === EmailType.CATCHALL
  }

  prepareFormData() {
    if (this.emailParam) {
      const splittedMail = this.emailParam.split("@")
      this.formData.address = splittedMail[0] || ''
      this.formData.domain = splittedMail[1] || ''
    }

    if (this.email) {
      this.formData.type = this.email.Recipients && this.email.Recipients.length > 0 ? EmailType.FORWARD : EmailType.EMAIL
      this.formData.recipients = [...this.email.Recipients || []]

      if (this.email.Autoresponder) {
        this.formData.responder.enabled = this.email.Autoresponder.Status == 'enabled'
        this.formData.responder.subject = this.email.Autoresponder.Subject
        this.formData.responder.message = this.email.Autoresponder.Text
        this.formData.responder.start = this.email.Autoresponder.ValidFrom
        this.formData.responder.end = this.email.Autoresponder.ValidUntil
      }
    }
  }

  addParticipantRow() {
    this.formData.recipients.push(this.recipientRow)
    this.recipientRow = ''
  }

  deleteRecipient(idx: number) {
    this.formData.recipients.splice(idx, 1)
  }

  onAutoresponderToggle(on: boolean) {
    this.formData.responder.enabled = on
  }

  createMail() {
    if (this.formData.type == 'email') {
      this.$api.email.createMail(
          `${this.formData.address}@${this.formData.domain}`,
          this.formData.password
      ).subscribe(
          value => {
            this.refreshMailRecords()
            router.push({ name: 'email-list' })
          },
          error => {
            console.error(error)
          }
      )
    } else if(this.formData.type == EmailType.FORWARD) {
      this.$api.email.createForward(
          `${this.formData.address}@${this.formData.domain}`,
          this.formData.recipients || []
      ).subscribe(
          value => {
            this.refreshMailRecords()
            router.push({ name: 'email-list' })
          },
          error => {
            console.error(error)
          }
      )
    } else if(this.formData.type == EmailType.CATCHALL) {
      this.$api.email.createCatchAll(
          `${this.formData.address}@${this.formData.domain}`,
          this.formData.recipients[0]
      ).subscribe(
          value => {
            this.refreshMailRecords()
            router.push({ name: 'email-list' })
          },
          error => {
            console.error(error)
          }
      )
    }
  }

  deleteMailFromStore() {
    if (confirm("Wollen Sie die E-Mail Adresse wirklich löschen?")) {
      this.deleteMail(this.emailParam)
      this.refreshMailRecords()
      router.push({ name: 'email-list' })
    }
  }

  changePassword() {
    this.$api.email
        .updatePassword(this.emailParam, this.formData.password)
        .subscribe(
            value => {
              alert("Passowrt erfolgreich geändert.")
            },
            error => {
              alert("Passowrt konnte nicht geändert werden.")
            }
        )
  }
}
</script>
<template>
  <h1 class="text-2xl font-semibold text-gray-900 text-left">
    Stammdaten
  </h1>

  <div class="mt-12">
    <form class="space-y-8" action="#" method="post">
      <fieldset class="flex">
        <label class="text-sm font-medium text-gray-700 flex items-center mr-6">
          <input
            v-model="formData.type"
            name="type"
            value="company"
            type="radio"
            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mr-2"
          />
          Firma/Verein/Stiftung
        </label>
        <label class="text-sm font-medium text-gray-700 flex items-center">
          <input
            v-model="formData.type"
            name="type"
            value="private"
            type="radio"
            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mr-2"
          />
          Privatperson
        </label>
      </fieldset>

      <fieldset
        :disabled="!isCompany"
        :class="isCompany ? 'opacity-1' : 'opacity-50'"
      >
        <div class="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-3">
            <label
              for="first-name"
              class="block text-sm font-medium text-gray-700"
            >
              Firmenname
            </label>
            <div class="mt-1">
              <input
                v-model="formData.company.title"
                type="text"
                name="title"
                autocomplete="company-title"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label
              for="last-name"
              class="block text-sm font-medium text-gray-700"
            >
              Firmenname 2
            </label>
            <div class="mt-1">
              <input
                v-model="formData.company.title2"
                type="text"
                name="title2"
                autocomplete="additional-company-title"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label
              for="first-name"
              class="block text-sm font-medium text-gray-700"
            >
              EU VAT ID (falls vorhanden)
            </label>
            <div class="mt-1">
              <input
                v-model="formData.company.vat"
                type="text"
                name="vat"
                autocomplete="vat"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label
              for="last-name"
              class="block text-sm font-medium text-gray-700"
            >
              E-Mail Adresse für Rechnungszusendung
            </label>
            <div class="mt-1">
              <input
                v-model="formData.company.billingMail"
                type="email"
                name="billing-mail"
                autocomplete="billing-mail"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div class="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-4">
            <label
              for="country"
              class="block text-sm font-medium text-gray-700"
            >
              Anrede
            </label>
            <div class="mt-1">
              <select
                v-model="formData.salutation"
                id="country"
                name="country"
                autocomplete="country"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              >
                <option>Herr</option>
                <option>Frau</option>
                <option>Mexico</option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-3">
            <label
              for="first-name"
              class="block text-sm font-medium text-gray-700"
            >
              Vorname
            </label>
            <div class="mt-1">
              <input
                v-model="formData.firstName"
                type="text"
                name="title"
                autocomplete="company-title"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label
              for="last-name"
              class="block text-sm font-medium text-gray-700"
            >
              Nachname
            </label>
            <div class="mt-1">
              <input
                v-model="formData.lastName"
                type="text"
                name="title2"
                autocomplete="additional-company-title"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div class="sm:col-span-6">
            <label
              for="last-name"
              class="block text-sm font-medium text-gray-700"
            >
              Straße
            </label>
            <div class="mt-1">
              <input
                v-model="formData.street"
                type="text"
                name="title2"
                autocomplete="additional-company-title"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div class="sm:col-span-2">
            <label
              for="last-name"
              class="block text-sm font-medium text-gray-700"
            >
              Postleitzahl
            </label>
            <div class="mt-1">
              <input
                v-model="formData.zip"
                type="text"
                name="title2"
                autocomplete="additional-company-title"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div class="sm:col-span-2">
            <label
              for="last-name"
              class="block text-sm font-medium text-gray-700"
            >
              Ort
            </label>
            <div class="mt-1">
              <input
                v-model="formData.city"
                type="text"
                name="title2"
                autocomplete="additional-company-title"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div class="sm:col-span-2">
            <label
              for="last-name"
              class="block text-sm font-medium text-gray-700"
            >
              Land
            </label>
            <div class="mt-1">
              <input
                v-model="formData.country"
                type="text"
                name="title2"
                autocomplete="additional-company-title"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label
              for="last-name"
              class="block text-sm font-medium text-gray-700"
            >
              Telefonnummer
            </label>
            <div class="mt-1">
              <input
                v-model="formData.telephone"
                type="text"
                name="title2"
                autocomplete="additional-company-title"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label
              for="last-name"
              class="block text-sm font-medium text-gray-700"
            >
              E-Mail Adresse
            </label>
            <div class="mt-1">
              <input
                :value="formData.email"
                type="text"
                name="title2"
                autocomplete="additional-company-title"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div class="space-y-4">
          <div class="relative flex items-start">
            <div class="flex items-center h-5">
              <input
                id="comments"
                name="comments"
                type="checkbox"
                class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
            <div class="ml-3 text-sm">
              <label for="comments" class="font-medium text-gray-700"
                >Ich möchte über neue Produkte und Aktionen per E-Mail
                informiert werden.</label
              >
            </div>
          </div>
        </div>
      </fieldset>

      <div class="pt-5">
        <div class="flex justify-end">
          <button
            type="button"
            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-system-blue hover:bg-system-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-system-blue"
          >
            Stammdaten speichern
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import UserStore from "@/store/user.store";
import { BaseData } from "@/modules/user/models/BaseData";
import { namespace } from "vuex-class";

const userStore = namespace("UserStore");

export default class UserBaseData extends Vue {
  @userStore.Action refreshBaseData!: () => void;
  @userStore.Getter("records") formData?: BaseData;

  get isCompany(): boolean {
    return this.formData?.type == "company";
  }

  beforeMount() {
    this.refreshBaseData();
  }
}
</script>

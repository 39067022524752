<template>
    <div class="flex flex-col">
        <div class="bg-system-blue rounded-md h-12 w-12 absolute self-center">
            <slot name="icon"></slot>
        </div>
        <div class="bg-system-gray-4 overflow-hidden shadow rounded-lg flex flex-col mt-6 pt-10 items-center">
            <router-link :to="link" class="font-medium text-system-blue hover:text-system-dark-blue">{{ linkTitle }}</router-link>
            <div class="p-5 flex-1 mt-3 text-system-gray-3 text-base">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component } from '@vue/runtime-core';
import { Options, Vue } from 'vue-class-component';


class Props {
  link!: string
  linkTitle!: string
}

export default class LargeCard extends Vue.with(Props) {

}
</script>
import axios, {AxiosInstance, AxiosResponse} from "axios"
import qs from "querystring"
import EmailRecordsResponse from "@/services/api/models/EmailRecordsResponse";
import {UserResponse} from "@/services/api/models/UserResponse";
import {namespace} from "vuex-class";
import store from "@/store";
import {ApiResponse} from "@/services/api/models/ApiResponse";
import TokenResponse from "@/services/api/models/TokenResponse";
import {from, Observable} from "rxjs";
import {map} from "rxjs/operators";
import EmailStore from "@/modules/email/store/email.store";
import LoginStore from "@/modules/login/store/login.store";
import router from "@/router";

const loginStore = namespace("LoginStore")

export interface WebserviceConfig {
    baseUrl: string
}

export default class Webservice {
    @loginStore.Action logout!: () => void

    private config: WebserviceConfig = {
        baseUrl: 'https://api5.dev.edis.at/v5'
    }
    private instance: AxiosInstance

    public user = {
        token: (user: string, pass: string) => {
            const creds = `${user}:${pass}`
            return this.call<TokenResponse>('/token', 'get',{ 'Authorization': `Basic ${btoa(creds)}` })
        },
        userData: () => {
            return this.call<ApiResponse<UserResponse>>('/login', 'get')
        }
    }

    public email = {
        getMails: (custNo: number) => {
            return this.call<ApiResponse<EmailRecordsResponse>>(`/clients/${custNo}/email`, 'get').pipe(map(result => result.data))
        },
        createMail: (email: string, password: string) => {
            return this.call<ApiResponse<EmailRecordsResponse>>(`/email/${email}`, 'post', {}, null, { 'Password': password }).pipe(map(result => result.data))
        },
        getMail: (email: string) => {
            return this.call<ApiResponse<any>>(`/email/${email}`, 'get').pipe(map(result => result.data))
        },
        deleteMail: (email: string) => {
            return this.call<ApiResponse<any>>(`/email/${email}`, 'delete').pipe(map(result => result.data))
        },
        updatePassword: (email: string, password: string) => {
            return this.call<ApiResponse<any>>(`/email/${email}/passwd`, 'patch', {}, null, { 'Password': password }).pipe(map(result => result.data))
        },
        createForward: (email: string, recipients: string[]) => {
            return this.call<ApiResponse<any>>(
                `/email/${email}/forward`,
                'put',
                { 'Content-Type': 'application/x-www-form-urlencoded' },
                null,
                qs.stringify({ 'Recipients[]': recipients })
            ).pipe(map(result => result.data))
        },
        createCatchAll: (email: string, recipient: string) => {
            return this.call<ApiResponse<any>>(
                `/email/${email}/catchall`,
                'put',
                { 'Content-Type': 'application/x-www-form-urlencoded' },
                null,
                qs.stringify({ 'Recipient': recipient })
            ).pipe(map(result => result.data))
        }
    }

    constructor() {
        this.instance = axios.create({
            baseURL: this.config.baseUrl,
            headers: {
                "Content-Type": "application/json",
            }
        });

        this.setupAxios()
    }

    private setupAxios() {
        // Add a request interceptor
        this.instance
            .interceptors
            .request
            .use((config) => {
                if (config.url?.search("token") === -1 && store.state.LoginStore.token) {
                    config.headers.Authorization = `Bearer ${store.state.LoginStore.token}`;
                }
                return config;
            }, (error) => {
                return error
            });

        // add a response interceptor
        this.instance
            .interceptors
            .response
            .use(response => {
                return response;
            }, error => {
                if (error.response.status === 401) {
                    store.commit('LoginStore/logout')
                    return router.push({ name: 'login' })
                }
                return Promise.reject(error);
            });
    }

    call<T>(path: string, method: 'get' | 'post' | 'delete' | 'patch' | 'put' = 'get', headers: any = {}, params?: any, data?: any): Observable<T> {
        const url = `${this.config.baseUrl}${path}`
        const response = this.instance.request<T>({
            url,
            method,
            headers: headers || {},
            params,
            data
        })
        return from(response).pipe(
            map((response: AxiosResponse<T>) => response.data)
        )
    }
}
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { UserResponse } from "@/services/api/models/UserResponse";
import { BaseData } from "@/modules/user/models/BaseData";
import store from "@/store";
import Webservice from "@/services/api/webservice";
import { ApiResponse } from "@/services/api/models/ApiResponse";

@Module({
  namespaced: true,
})
export default class UserStore extends VuexModule {
  userData?: UserResponse = undefined;
  baseData?: BaseData = undefined;

  @Mutation
  setUserData(data: UserResponse): void {
    this.userData = data;
  }

  @Mutation
  setBaseData(baseData: BaseData): void {
    this.baseData = baseData;
  }

  @Action
  refreshBaseData() {
    new Webservice().user.userData().subscribe(
      (value: ApiResponse<UserResponse>) => {
        this.context.commit("setUserData", value.data);
      },
      (error) => {
        console.log("error:", error);
      }
    );
    const userData = this.userData;
    if (userData) {
      const data: BaseData = {
        type: "company",
        company: {
          title: userData.CompanyName1,
          title2: userData.CompanyName2,
          vat: userData.VatNo,
          billingMail: userData.Email,
        },
        salutation: 2,
        firstName: userData.Forename,
        lastName: userData.Surname,
        email: userData.Email,
        telephone: userData.Telephone,
        street: userData.Street,
        zip: userData.Zip,
        city: userData.City,
        country: userData.Country,
      };
      this.context.commit("setBaseData", data);
    }
  }

  get records() {
    return this.baseData;
  }
}

import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import TokenResponse from "@/services/api/models/TokenResponse";
import store from "@/store";

@Module({
  namespaced: true,
})
export default class LoginStore extends VuexModule {
  token?: string = undefined;

  @Mutation
  setTokenData(data: TokenResponse): void {
    this.token = data.token;
  }

  @Mutation
  logout() {
    //TODO
    this.token = undefined;
  }
}

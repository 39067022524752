
import {Options, Vue} from "vue-class-component";
import {Switch, SwitchGroup, SwitchLabel} from '@headlessui/vue'

@Options({
  components: {
    Switch,
    SwitchLabel,
    SwitchGroup
  }
})
export default class Toggle extends Vue {
  enabled = false

  get isEnabled() {
    return this.enabled
  }

  set isEnabled(value: boolean) {
    this.enabled = value
    this.$emit('onToggle', value)
  }
}


import { Vue } from "vue-class-component";
import UserStore from "@/store/user.store";
import { BaseData } from "@/modules/user/models/BaseData";
import { namespace } from "vuex-class";

const userStore = namespace("UserStore");

export default class UserBaseData extends Vue {
  @userStore.Action refreshBaseData!: () => void;
  @userStore.Getter("records") formData?: BaseData;

  get isCompany(): boolean {
    return this.formData?.type == "company";
  }

  beforeMount() {
    this.refreshBaseData();
  }
}

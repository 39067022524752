<template>
<svg viewBox="0 0 17.591 21.906">
  <g id="np_bills_1635619_000000">
    <path id="Pfad_1" data-name="Pfad 1" d="M30.4,21.426H18.583a1.182,1.182,0,0,0-1.236,1.182V39.65l1.594-1.485a.715.715,0,0,1,.934,0l2.089,1.869,2.061-1.869h0a.741.741,0,0,1,.934,0l2.061,1.869,2.089-1.869a.715.715,0,0,1,.934,0l1.594,1.485V22.608A1.182,1.182,0,0,0,30.4,21.426Zm-9.319,4.123H24.6a.412.412,0,1,1,0,.825H21.085a.412.412,0,0,1,0-.825ZM27.9,33.521H21.085a.412.412,0,0,1,0-.825H27.9a.412.412,0,0,1,0,.825Zm0-3.573H21.085a.412.412,0,0,1,0-.825H27.9a.412.412,0,0,1,0,.825Z" transform="translate(-17.347 -18.127)"/>
    <path id="Pfad_2" data-name="Pfad 2" d="M42.649,9.183H30.83a1.263,1.263,0,0,0-1.236,1.265v1.209H39.35a2.034,2.034,0,0,1,2.061,2.007V25.95a.713.713,0,0,1,.88.027l1.594,1.484V10.448a1.264,1.264,0,0,0-1.236-1.265Z" transform="translate(-26.294 -9.183)"/>
  </g>
</svg>
</template>

<script lang="ts">

import {Vue} from "vue-class-component";

export default class EdisSheetsIcon extends Vue {

}

</script>

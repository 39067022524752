<template>
  <svg viewBox="0 0 33.607 27.437">
    <path id="np_paperplane_1392357_000000" d="M4.812,24.62a1.065,1.065,0,0,0-.083,1.918l4.955,2.433a1.4,1.4,0,0,0,1.154.039l12.947-5.324c.187-.078.327.192.169.326l-8.172,6.875-.115.1a1.833,1.833,0,0,0-.639,1.407v6.961a.533.533,0,0,0,.945.379l4.374-5.564,4.442,2.211a1.085,1.085,0,0,0,1.457-.52L37.726,13.1a.4.4,0,0,0-.457-.581Z" transform="translate(-4.166 -12.498)"/>
  </svg>
</template>

<script lang="ts">

import {Vue} from "vue-class-component";

export default class EdisMailIcon extends Vue {

}

</script>

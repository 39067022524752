
import { Options, Vue } from 'vue-class-component';
import { LargeCard, FooterCard, AvailableSpace } from '@/common/components'
import { EdisCloudIcon, EdisDashboardIcon, EdisEarthIcon, EdisMailIcon, EdisRackIcon } from '@/common/icons'
import { CardData } from '@/common/models'

@Options({
  components: {
    FooterCard,
    LargeCard,
    EdisRackIcon,
    EdisCloudIcon,
    EdisDashboardIcon,
    EdisMailIcon,
    AvailableSpace
  },
})
export default class Home extends Vue {
  largeCards: CardData[] = [
    {
      title: "2 neue Emails",
      content: 'Sie verwalten 3 Postfächer und 1 Weiterleitung. 80% verfügbarer Speicher.',
      icon: EdisMailIcon,
      link: 'dashboard'
    },
    {
      title: "Webspace",
      content: 'Sie verwalten 3 Server. 50% verfügbarer Speicher.',
      icon: EdisCloudIcon,
      link: 'email',
    },
    {
      title: "Domains",
      content: 'Sie verwalten 13 Domains. 3 sind noch verfügabr in Ihrem Paket.',
      icon: EdisEarthIcon,
      link: 'dashboard',
    }
  ]

  cards: CardData[] = [
    {
      title: "Aktuelle Speicherauslastung",
      content: '<div class="font-bold text-2xl mr-6">53,8 GB</div><div class="text-sm text-system-green">20%</div>',
      icon: EdisRackIcon,
      link: 'dashboard',
      component: AvailableSpace,
      componentExtras: {
        bytesLeft: 53000000000 * 1.3,
        bytesTotal: 53000000000 * 2
      }
    },
    {
      title: "Aktuelle Speicherauslastung",
      content: '<div class="font-bold text-2xl mr-6">53,8 GB</div><div class="text-sm text-system-green">20%</div>',
      icon: EdisCloudIcon,
      link: 'email',
      component: AvailableSpace,
      componentExtras: {
        bytesLeft: 53000000000 * 0.8,
        bytesTotal: 53000000000 * 2
      }
    },
        {
      title: "Aktuelle Speicherauslastung",
      content: '<div class="font-bold text-2xl mr-6">53,8 GB</div><div class="text-sm text-system-green">20%</div>',
      icon: EdisDashboardIcon,
      link: 'dashboard',
      component: AvailableSpace,
      componentExtras: {
        bytesLeft: 53000000000 * 0.15,
        bytesTotal: 53000000000 * 2
      }
    },
  ]

}

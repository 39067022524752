<template>
  <div class="mx-auto px-4 sm:px-6 relative bg-white">
    <div class="flex justify-between items-center py-6 md:justify-start md:space-x-10">
      <div class="flex justify-start lg:w-0 lg:flex-1">
        <a href="#">
          <span class="sr-only">EDIS</span>
          <img class="h-8 w-auto sm:h-10" src="@/assets/logo.png" alt="" />
        </a>
      </div>

      <template v-if="!token">
        <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <a href="#" class="whitespace-nowrap text-base font-medium text-system-blue hover:text-system-dark-blue underline">
            Neu bei EDIS? Dann geht’s hier zur Registrierung!
          </a>
        </div>
      </template>
      <template v-else>
        <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <Popover class="relative">
            <PopoverButton class="group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none">
              <span class="inline-flex items-center justify-center h-11 w-11 rounded-full bg-system-blue">
                <span class="text-sm font-medium leading-none text-white">DB</span>
              </span>
            </PopoverButton>

            <transition enter-active-class="transition ease-out duration-150" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
              <PopoverPanel class="absolute right-0 z-10 mt-3 px-2 w-screen max-w-xs sm:px-0">
                <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                    <a v-for="item in solutions" :key="item.name" :href="item.href" class="-m-3 p-3 block rounded-md hover:bg-gray-50 transition ease-in-out duration-150 flex-shrink-0 group">
                      <div class="flex items-center">
                        <div>
                          <component :is="item.icon" class="inline-block h-6 w-6 text-system-blue fill-current" ></component>
                        </div>
                        <div class="ml-3">
                          <p class="text-md font-medium text-gray-700 group-hover:text-gray-900">
                            {{ item.name }}
                          </p>
                        </div>
                      </div>
                    </a>

                    <router-link to="login" @click="logout" class="-m-3 mt-1 p-3 block rounded-md hover:bg-gray-50 transition ease-in-out duration-150 flex-shrink-0 group">
                      <div class="flex items-center">
                        <div>
                          <logout-icon class="inline-block h-6 w-6 text-system-blue" ></logout-icon>
                        </div>
                        <div class="ml-3">
                          <p class="text-md font-medium text-gray-700 group-hover:text-gray-900">
                            Logout
                          </p>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {namespace} from "vuex-class";
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { LogoutIcon, ChevronDownIcon } from "heroicons-vue3/outline";
import { EdisCloudIcon, EdisEarthIcon, EdisProfileIcon, EdisRackIcon, EdisSheetsIcon } from '../icons';

const loginStore = namespace('LoginStore')

@Options({
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    ChevronDownIcon,
    LogoutIcon,
    EdisProfileIcon,
    EdisSheetsIcon,
    EdisEarthIcon,
    EdisRackIcon,
    EdisCloudIcon
  }
})
export default class Header extends Vue {
  @loginStore.Mutation
  public logout!: () => void

  @loginStore.State
  private token?: string

  solutions = [
    {
      name: 'Stammdaten',
      href: '/user',
      icon: EdisProfileIcon
    },
    {
      name: 'Rechnungen',
      href: '/billing',
      icon: EdisSheetsIcon
    },
    {
      name: 'Domains',
      href: '#',
      icon: EdisEarthIcon
    },
    {
      name: 'Hostings',
      href: '#',
      icon: EdisRackIcon
    }
  ]
}
</script>


import { Component } from '@vue/runtime-core';
import { Options, Vue } from 'vue-class-component';


class Props {
  link!: string
  linkTitle!: string
}

export default class LargeCard extends Vue.with(Props) {

}

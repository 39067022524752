<template>
  <svg viewBox="0 0 40.831 26.889">
    <path id="np_cloud_677894_000000" d="M25.432,23A10.16,10.16,0,0,0,15.426,34.095a7.973,7.973,0,0,0,1.541,15.794h24.4c5.063,0,8.465-3.322,8.465-7.469a7.55,7.55,0,0,0-5.975-7.469c0-5.975-5.477-8.465-9.461-6.473A9.941,9.941,0,0,0,25.432,23Z" transform="translate(-9 -23)"/>
  </svg>
</template>

<script lang="ts">

import {Vue} from "vue-class-component";

export default class EdisCloudIcon extends Vue {

}

</script>

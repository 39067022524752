<template>
    <div class="flex items-center mt-1 flex-wrap">
        <div class="font-bold text-2xl mr-6">{{ spaceLeft }}</div>
        <div :class="[color, 'text-sm']">{{ spaceLeftPercentage.toFixed(2) }}%</div>
    </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import Utils from '@/common/Utils'

export class AvailableSpaceProps {
    bytesLeft!: number
    bytesTotal!: number
}

export default class AvailableSpace extends Vue.with(AvailableSpaceProps) {
    get spaceLeft(): string {
        return Utils.formatBytes(this.bytesLeft);
    }

    get spaceLeftPercentage(): number {
        return (this.bytesLeft / this.bytesTotal * 100);
    }

    get color(): string {
        const spaceLeft = this.spaceLeftPercentage
        if (spaceLeft > 50) {
            return 'text-system-green'
        } else if(spaceLeft > 10) {
            return 'text-system-orange'
        } else {
            return 'text-system-red'
        }
    }
}
</script>
import { createLogger, Store } from "vuex";
import createPersistedState from "vuex-persistedstate";

const debug = process.env.NODE_ENV !== "production";

import LoginStore from "@/modules/login/store/login.store";
import EmailStore from "@/modules/email/store/email.store";
import AppStore from "@/store/app.store";
import UserStore from "@/store/user.store";
import BaseDataStore from "@/modules/email/store/email.store";

const loginState = createPersistedState({
  //only token in LoginStore
  paths: ["LoginStore"],
});

export interface State {
  LoginStore: LoginStore;
  EmailStore: EmailStore;
  AppStore: AppStore;
  UserStore: UserStore;
}

export default new Store<State>({
  strict: debug,
  plugins: debug ? [createLogger(), loginState] : [loginState],
  modules: {
    AppStore,
    LoginStore,
    EmailStore,
    UserStore,
  },
});

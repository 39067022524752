
import {Options, Vue} from "vue-class-component";
import {PlusSmIcon, TrashIcon} from "heroicons-vue3/outline";
import {Toggle} from '@/common/components'
import {namespace} from "vuex-class";
import router from "@/router";
import {Observable} from "rxjs";
import {EmailAddress} from "@/services/api/models/EmailRecordsResponse";
import {EmailType} from "@/modules/email/models/email-type";

interface EmailFormData {
  type: EmailType,
  address: string
  domain: string
  recipients: string[],
  password: string,
  passwordConfirm: string,
  responder: {
    enabled: boolean,
    start?: string,
    end?: string,
    subject: string,
    message: string
  }
}

const emailStore = namespace("EmailStore")

@Options({
  components: {
    PlusSmIcon,
    TrashIcon,
    Toggle
  }
})
export default class EmailCreation extends Vue {

  @emailStore.Getter('domains') domains?: string[] = undefined
  @emailStore.Getter('loadedEmail') email!: EmailAddress

  @emailStore.Action refreshMailRecords!: () => void
  @emailStore.Action deleteMail!: (address: string) => Observable<any>
  @emailStore.Action loadEmail!: (address: string) => Promise<any>

  recipientRow = ''

  formData: EmailFormData = {
    type: EmailType.EMAIL,
    address: '',
    domain: '',
    recipients: [],
    password: '',
    passwordConfirm: '',
    responder: {
      enabled: false,
      start: '',
      end: '',
      subject: '',
      message: ''
    }
  }

  beforeMount() {
    if (this.isEditMode) {
      this.loadEmail(this.emailParam)
          .then(value => {
            this.prepareFormData()
          })
    }
  }

  get emailParam(): string {
    const address = this.$route.params.address
    return Array.isArray(address) ? address[0] : address
  }

  get isEditMode() {
    return this.emailParam || false
  }

  get isForward() {
    return this.formData.type === EmailType.FORWARD
  }

  get isCatchAll() {
    return this.formData.type === EmailType.CATCHALL
  }

  prepareFormData() {
    if (this.emailParam) {
      const splittedMail = this.emailParam.split("@")
      this.formData.address = splittedMail[0] || ''
      this.formData.domain = splittedMail[1] || ''
    }

    if (this.email) {
      this.formData.type = this.email.Recipients && this.email.Recipients.length > 0 ? EmailType.FORWARD : EmailType.EMAIL
      this.formData.recipients = [...this.email.Recipients || []]

      if (this.email.Autoresponder) {
        this.formData.responder.enabled = this.email.Autoresponder.Status == 'enabled'
        this.formData.responder.subject = this.email.Autoresponder.Subject
        this.formData.responder.message = this.email.Autoresponder.Text
        this.formData.responder.start = this.email.Autoresponder.ValidFrom
        this.formData.responder.end = this.email.Autoresponder.ValidUntil
      }
    }
  }

  addParticipantRow() {
    this.formData.recipients.push(this.recipientRow)
    this.recipientRow = ''
  }

  deleteRecipient(idx: number) {
    this.formData.recipients.splice(idx, 1)
  }

  onAutoresponderToggle(on: boolean) {
    this.formData.responder.enabled = on
  }

  createMail() {
    if (this.formData.type == 'email') {
      this.$api.email.createMail(
          `${this.formData.address}@${this.formData.domain}`,
          this.formData.password
      ).subscribe(
          value => {
            this.refreshMailRecords()
            router.push({ name: 'email-list' })
          },
          error => {
            console.error(error)
          }
      )
    } else if(this.formData.type == EmailType.FORWARD) {
      this.$api.email.createForward(
          `${this.formData.address}@${this.formData.domain}`,
          this.formData.recipients || []
      ).subscribe(
          value => {
            this.refreshMailRecords()
            router.push({ name: 'email-list' })
          },
          error => {
            console.error(error)
          }
      )
    } else if(this.formData.type == EmailType.CATCHALL) {
      this.$api.email.createCatchAll(
          `${this.formData.address}@${this.formData.domain}`,
          this.formData.recipients[0]
      ).subscribe(
          value => {
            this.refreshMailRecords()
            router.push({ name: 'email-list' })
          },
          error => {
            console.error(error)
          }
      )
    }
  }

  deleteMailFromStore() {
    if (confirm("Wollen Sie die E-Mail Adresse wirklich löschen?")) {
      this.deleteMail(this.emailParam)
      this.refreshMailRecords()
      router.push({ name: 'email-list' })
    }
  }

  changePassword() {
    this.$api.email
        .updatePassword(this.emailParam, this.formData.password)
        .subscribe(
            value => {
              alert("Passowrt erfolgreich geändert.")
            },
            error => {
              alert("Passowrt konnte nicht geändert werden.")
            }
        )
  }
}

<template>
  <svg viewBox="0 0 33.607 33.607">
    <path id="np_globe_1939577_000000" d="M18.066,10.536c0,.336-.869.869-1.1,1.359s.591,1.557.164,1.62-.852-1.607-3.144-.983c-.691.187-2.228.983-1.409,2.6s2.177-.46,2.637-.228-.467,1.262-.37,1.278,1.63.043,1.695,1.443,1.819,1.278,2.194,1.312,1.637-1.033,1.819-1.08.9-.671,2.473.245,2.375,1.1,2.916,1.484.164.8.671,1.041,2.54-.08,3.046.755-2.1,5.033-2.916,5.489-1.194,1.506-2.013,2.177a26.644,26.644,0,0,1-3.046,1.811c-.957.57-1.131,1.925-1.557,2.245h0a16.75,16.75,0,1,0-.336.074c-.433-.208-.48-1.755-.05-2.637.477-.983,1.966-3.473.49-4.308s-1.075-1.215-1.974-2.182-.2-1.11-.256-1.359a3.08,3.08,0,0,1,.585-1.938,6.208,6.208,0,0,0,.033-1.1c0-.148-.671-.54-.835-.557s-.245.262-.477.278a5.412,5.412,0,0,1-1.443-.768c-.214-.161-.308-.533-.6-.843s-.336-.067-.785-.245-1.933-.721-3.063-1.181-1.228-1.1-1.245-1.557-.688-1.114-1.007-1.59-.372-1.131-.486-.983.591,1.869.477,1.916-.359-.477-.688-.9.336-.2-.705-2.261.336-3.117.393-4.194.886.393.46-.295.034-2.131-.295-2.654S6.127,5.078,6.127,5.078c.05-.507,1.637-2.047,2.785-2.848s1.849-.181,2.768.114.983.2.671-.1.131-.443.852-.336.916.983,2.013.9.111.219.253.494-.164.245-.886.738,0,.49,1.295,1.426.886-.624.755-1.312.933-.148.933-.148c.785.523.641.03,1.215.208a11.922,11.922,0,0,1,2.127,1.5c-1.949,1.064-.721,1.181-.393,1.426s-.671.721-.671.721c-.409-.409-.477,0-.738.164m-4.187-6.4c-.121-.09.094-.185.215-.336A2.325,2.325,0,0,1,15.242.96c.245-.245,1.46-.591,1.222.08s-1.363.716-1.544.591Zm.886.9c-.591-.05-.835-.376-.54-.409s1.473-.336,1.678-.155,1.262.927,1.342,1.239,0,.574-.409.557m3.532-.451c-.336.262-1.976-.6-2.295-.876a3.85,3.85,0,0,0-2.4-.99c-.288-.187-.185-.46.256-.852S18.644.723,19.375.8s1.557.637,1.574,1.3.785.94.459,1.2Z" transform="translate(0.452 0.07)"/>
  </svg>
</template>

<script lang="ts">

import {Vue} from "vue-class-component";

export default class EdisEarthIcon extends Vue {

}

</script>
